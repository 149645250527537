import React from "react";
import "./HomeHeader.css";

function HomeHeader() {
  return (
    <div>
      <div className="home-header-component">
        <h1>Creamy Scoops</h1>
        <a
          href={sessionStorage.getItem("featureFlag") ? "menu" : "order"}
          className="order-now-button"
        >
          {sessionStorage.getItem("featureFlag") ? "View Menu" : "Order Now"}
        </a>
      </div>
    </div>
  );
}

export default HomeHeader;
