import React from "react";
import "./NavBar.css";

function NavBarOwnerView() {
  return (
    <div>
      <div className="navbar-component" style={{ height: "7vh" }}>
        <div className="navbar-logo">
          <a href="/">
            <img
              src="/images/Creamy_Scoops_Logo.jpeg"
              width="55px"
              style={{ marginRight: "5px" }}
            ></img>
            <div className="navbar-log-text">Creamy Scoops</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default NavBarOwnerView;
