import React from "react";
import PropTypes from "prop-types";
import "./FloatsMenu.css";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";

const FloatsMenu = ({ menuItems }) => {
  const generateFloatsForm = () => {
    return (
      <div className="float-menu-options-container">
        {
          // eslint-disable-next-line no-unused-vars
          menuItems.floats.options.map((float, key) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="float-menu-option">
                <div className="menu-option-label">
                  <div>{float.float}</div>
                  <div style={{ fontSize: "small" }}>${float.price}</div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  const generateBottledSodaForm = () => {
    return (
      <div className="float-menu-options-container">
        {menuItems.floats.bottledSoda.map((bottledSoda) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="float-menu-option">
              <div className="menu-option-label">
                <div>{bottledSoda}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateCannedSodaForm = () => {
    return (
      <div className="float-menu-options-container">
        {menuItems.floats.cannedSoda.map((cannedSoda) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="float-menu-option">
              <div className="menu-option-label">
                <div>{cannedSoda}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Floats"}
        formFunction={generateFloatsForm}
      />
      <CollapsibleContainerOrder
        formTitle={"Bottled Soda"}
        formFunction={generateBottledSodaForm}
      />
      <CollapsibleContainerOrder
        formTitle={"Canned Soda"}
        formFunction={generateCannedSodaForm}
      />
    </div>
  );
};

FloatsMenu.propTypes = {
  menuItems: PropTypes.object,
};

export default FloatsMenu;
