import React, { useEffect, useState } from "react";
import "./IceCreamOrder.css";
import { useNavigate, useLocation } from "react-router-dom";
import NumberOfScoopsFrom from "./NumberOfScoopsForm";
import FlavorsForm from "./FlavorsForm";
import CupConeBowlForm from "./CupConeBowlForm";
import FreeToppingsForm from "./FreeToppingsForm";
import ToppingsForm from "./ToppingsForm";
import AddOnsForm from "./AddOnsForm";

function IceCreamOrder() {
  const [menuItems, setMenuItems] = useState(null);
  const [oldNumberOfScoops, setOldNumberOfScoops] = useState(null);
  const [numberOfScoops, setNumberOfScoops] = useState(null);
  const [flavors, setFlavors] = useState([null, null, null]);
  const [freeToppings, setFreeToppings] = useState([]);
  const [toppings, setToppings] = useState([]);
  const [cupConeBowl, setCupConeBowl] = useState(null);
  const [addOns, setAddOns] = useState([]);
  const [itemPrice, setItemPrice] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [priceBreakdown, setPriceBreakdown] = useState({
    numberOfScoops: 0,
    cupConeBowl: 0,
    toppings: 0,
    addOns: 0,
  });

  const { state } = useLocation();
  const navigate = useNavigate();

  async function submitIceCreamForm() {
    if (state) {
      const iceCreamOrderData = {
        numberOfScoops: numberOfScoops,
        flavors: flavors,
        freeToppings: freeToppings,
        toppings: toppings,
        coneOrBowl: cupConeBowl,
        addOns: addOns,
      };
      let request = {};
      request["sessionID"] = sessionStorage.getItem("uuid");
      request["orderID"] = state.orderKey;
      request["order"] = {
        orderType: "Ice Cream",
        orderDetails: iceCreamOrderData,
        priceBreakdown: priceBreakdown,
        totalItemCost: itemPrice,
      };
      // eslint-disable-next-line no-undef
      await fetch(`${process.env.REACT_APP_API_URL}/edit-order-item`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
      navigate("/cart");
    } else {
      const iceCreamOrderData = {
        numberOfScoops: numberOfScoops,
        flavors: flavors,
        freeToppings: freeToppings,
        toppings: toppings,
        coneOrBowl: cupConeBowl,
        addOns: addOns,
      };
      let request = {};
      request["sessionID"] = sessionStorage.getItem("uuid");
      request["order"] = {
        orderType: "Ice Cream",
        orderDetails: iceCreamOrderData,
        priceBreakdown: priceBreakdown,
        totalItemCost: itemPrice,
      };
      // eslint-disable-next-line no-undef
      await fetch(`${process.env.REACT_APP_API_URL}/create-order-item`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });
      navigate("/cart");
    }
  }

  const editOrderFields = (iceCreamOrder, priceBreakdown) => {
    setNumberOfScoops(iceCreamOrder.numberOfScoops);
    if (iceCreamOrder.flavors.length === 1) {
      setFlavors(iceCreamOrder.flavors.push(...Array(2).fill(null)));
    }
    if (iceCreamOrder.flavors.length === 2) {
      setFlavors(iceCreamOrder.flavors.push(...Array(1).fill(null)));
    }
    if (iceCreamOrder.flavors.length === 3) {
      setFlavors(iceCreamOrder.flavors);
    }
    setFreeToppings(
      iceCreamOrder.freeToppings === undefined
        ? []
        : iceCreamOrder.freeToppings,
    );
    setToppings(
      iceCreamOrder.toppings === undefined ? [] : iceCreamOrder.toppings,
    );
    setCupConeBowl(iceCreamOrder.coneOrBowl);
    setAddOns(iceCreamOrder.addOns === undefined ? [] : iceCreamOrder.addOns);
    setPriceBreakdown({
      numberOfScoops: priceBreakdown.numberOfScoops,
      cupConeBowl: priceBreakdown.cupConeBowl,
      toppings: priceBreakdown.toppings,
      addOns: priceBreakdown.addOns,
    });
  };

  const setMenuItemsHelper = (menuItemsResponse) => {
    setMenuItems(menuItemsResponse);
    sessionStorage.setItem("menuItems", JSON.stringify(menuItemsResponse));
  };

  useEffect(() => {
    async function getAvailableMenuItems() {
      // eslint-disable-next-line no-undef
      await fetch(`${process.env.REACT_APP_API_URL}/get-menu`)
        .then((response) => response.json())
        .then((menuItemsResponse) => setMenuItemsHelper(menuItemsResponse));
    }
    async function getOrder() {
      await fetch(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_API_URL}/get-order-item?` +
          new URLSearchParams({
            sessionID: sessionStorage.getItem("uuid"),
            orderID: state.orderKey,
          }),
      )
        .then((response) => response.json())
        .then((customerOrder) =>
          editOrderFields(
            customerOrder.orderDetails,
            customerOrder.priceBreakdown,
          ),
        );
    }
    // const currentMenuItems = sessionStorage.getItem('menuItems')
    // if (currentMenuItems === null) {
    //     getAvailableMenuItems()
    // } else {
    //     const menuItemsSessionStorage = JSON.parse(sessionStorage.getItem('menuItems'))
    //     setMenuItems(menuItemsSessionStorage)
    // }
    getAvailableMenuItems();
    if (state !== null) {
      getOrder();
    }
  }, []);

  useEffect(() => {
    if (numberOfScoops < oldNumberOfScoops) {
      let modifiedFlavors = [...flavors];
      for (let i = numberOfScoops; i < oldNumberOfScoops; i++) {
        modifiedFlavors[i] = null;
      }
      setFlavors(modifiedFlavors);
    }
  }, [oldNumberOfScoops, numberOfScoops]);

  useEffect(() => {
    setItemPrice(
      priceBreakdown.numberOfScoops +
        priceBreakdown.cupConeBowl +
        priceBreakdown.toppings +
        priceBreakdown.addOns,
    );
  }, [priceBreakdown]);

  useEffect(() => {
    setSubmitDisabled(
      !(
        numberOfScoops !== null &&
        flavors.slice(0, numberOfScoops).every((flavor) => flavor !== null) &&
        cupConeBowl !== null
      ),
    );
  }, [numberOfScoops, flavors, cupConeBowl]);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  return (
    <div>
      {/* <NavBar /> */}
      {/* <SecondaryHeader /> */}
      <div className="forms-container">
        <h2 style={{ textAlign: "center" }}>Build Your Ice Cream!</h2>
        <div style={{ textAlign: "center", paddingBottom: "20px" }}>
          To order an ice cream, first, choose your desired number of scoops
          below. Afterwards, follow the instructions to choose from our
          delicious variety of flavors, toppings, etc.
        </div>
        {menuItems && (
          <NumberOfScoopsFrom
            numberOfScoops={numberOfScoops}
            setNumberOfScoops={setNumberOfScoops}
            setOldNumberOfScoops={setOldNumberOfScoops}
            menuItems={menuItems.scoops}
            priceBreakdown={priceBreakdown}
            setPriceBreakdown={setPriceBreakdown}
          ></NumberOfScoopsFrom>
        )}
        {numberOfScoops && menuItems && (
          <div>
            <FlavorsForm
              numberOfScoops={numberOfScoops}
              flavors={flavors}
              setFlavors={setFlavors}
              menuItems={menuItems.flavors}
            ></FlavorsForm>
            <CupConeBowlForm
              cupConeBowl={cupConeBowl}
              setCupConeBowl={setCupConeBowl}
              menuItems={menuItems.coneCupBowl}
              priceBreakdown={priceBreakdown}
              setPriceBreakdown={setPriceBreakdown}
            ></CupConeBowlForm>
            <FreeToppingsForm
              freeToppings={freeToppings}
              setFreeToppings={setFreeToppings}
              menuItems={menuItems.freeToppings}
            ></FreeToppingsForm>
            <ToppingsForm
              toppings={toppings}
              setToppings={setToppings}
              menuItems={menuItems.toppings}
              priceBreakdown={priceBreakdown}
              setPriceBreakdown={setPriceBreakdown}
            ></ToppingsForm>
            <AddOnsForm
              addOns={addOns}
              setAddOns={setAddOns}
              menuItems={menuItems.addOns}
              priceBreakdown={priceBreakdown}
              setPriceBreakdown={setPriceBreakdown}
            ></AddOnsForm>
          </div>
        )}
      </div>
      <div className="submit-button-container">
        {numberOfScoops && (
          <div style={{ fontSize: "1rem" }}>
            Total Price: ${Number(itemPrice).toFixed(2)}
          </div>
        )}
        {numberOfScoops && (
          <button
            className={submitDisabled ? "submit-disabled" : ""}
            type="button"
            value="Place Order"
            onClick={() => submitIceCreamForm()}
            disabled={submitDisabled}
          >
            Place Order
          </button>
        )}
        {numberOfScoops && submitDisabled && (
          <h6>
            *In order to place order, please fill out all the required fields!*
          </h6>
        )}
      </div>
    </div>
  );
}

export default IceCreamOrder;
