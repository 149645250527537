import React from "react";
import PropTypes from "prop-types";
import "./IceCreamMenu.css";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";

const IceCreamMenu = ({ menuItems }) => {
  const generateNumberOfScoopsMenu = () => {
    return (
      <div className="ice-cream-menu-options-container">
        {
          // eslint-disable-next-line no-unused-vars
          menuItems.iceCream.scoops.map((numberOfScoopsData, key) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="ice-cream-menu-option">
                <div className="menu-option-label">
                  <div>{numberOfScoopsData.optionName}</div>
                  <div style={{ fontSize: "small" }}>
                    ${numberOfScoopsData.price}
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  const generateIngredientsWarning = (df, gf, sf) => {
    const ingredientsWarningList = [];
    if (df !== "No") {
      ingredientsWarningList.push("DF");
    }
    if (gf !== "No") {
      ingredientsWarningList.push("GF");
    }
    if (sf !== "No") {
      ingredientsWarningList.push("SF");
    }

    return ingredientsWarningList.join(", ");
  };

  const generateFlavorsMenu = () => {
    return (
      <div className="ice-cream-menu-options-container">
        {menuItems.iceCream.flavors.map((flavorData) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="ice-cream-flavors-menu-option">
              <div className="menu-option-label">
                <div>{flavorData.flavorName}</div>
              </div>
              <div className="flavors-menu-option-ingredients-info">
                {generateIngredientsWarning(
                  flavorData.dairyFree,
                  flavorData.glutenFree,
                  flavorData.sugarFree,
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateFreeToppingsForm = () => {
    return (
      <div className="ice-cream-menu-options-container">
        {menuItems.iceCream.freeToppings.map((freeTopping) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="ice-cream-menu-option">
              <div className="menu-option-label">
                <div>{freeTopping}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateToppingsForm = () => {
    return (
      <div
        className="ice-cream-menu-options-container"
        style={{ width: "100%" }}
      >
        <h5
          style={{
            marginTop: "0%",
            marginBottom: "1%",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          Each additional topping is $0.50 extra
        </h5>
        {menuItems.iceCream.toppings.map((topping) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="ice-cream-menu-option">
              <div className="menu-option-label">
                <div>{topping}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateSyrupsAndSaucesForm = () => {
    return (
      <div className="ice-cream-menu-options-container">
        <h5
          style={{
            marginTop: "0%",
            marginBottom: "1%",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          Each additional topping is $0.50 extra
        </h5>
        {menuItems.iceCream.syrupsAndSauces.map((syrupAndSauce) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="ice-cream-menu-option">
              <div className="menu-option-label">
                <div>{syrupAndSauce}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateHoldersForm = () => {
    return (
      <div className="ice-cream-menu-options-container">
        {menuItems.iceCream.holders.map((holder) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="ice-cream-menu-option">
              <div className="menu-option-label">
                <div>{holder.holderOption}</div>
                <div style={{ fontSize: "small" }}>
                  {holder.price === "0.00" && <div>No Charge</div>}
                  {holder.price !== "0.00" && <div>${holder.price}</div>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateAddOnsForm = () => {
    return (
      <div className="ice-cream-menu-options-container">
        <h5
          style={{
            marginTop: "0%",
            marginBottom: "1%",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          Each add-on is $1.00 extra
        </h5>
        {menuItems.iceCream.addOns.map((addOn) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="ice-cream-menu-option">
              <div className="menu-option-label">
                <div>{addOn}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Number of Scoops"}
        formFunction={generateNumberOfScoopsMenu}
      />
      <CollapsibleContainerOrder
        formTitle={"Flavors"}
        formFunction={generateFlavorsMenu}
      />
      <CollapsibleContainerOrder
        formTitle={"Free Toppings"}
        formFunction={generateFreeToppingsForm}
      />
      <CollapsibleContainerOrder
        formTitle={"Toppings"}
        formFunction={generateToppingsForm}
      />
      <CollapsibleContainerOrder
        formTitle={"Syrups and Sauces"}
        formFunction={generateSyrupsAndSaucesForm}
      />
      <CollapsibleContainerOrder
        formTitle={"Cup, Cone, or Bowl"}
        formFunction={generateHoldersForm}
      />
      <CollapsibleContainerOrder
        formTitle={"Add Ons"}
        formFunction={generateAddOnsForm}
      />
    </div>
  );
};

IceCreamMenu.propTypes = {
  menuItems: PropTypes.object,
};

export default IceCreamMenu;
