import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CartOrder from "./CartOrder";
import Modal from "./../Shared/Modal";
import "./Cart.css";

function Cart() {
  const [cart, setCart] = useState(null);
  const [iceCreamOrdersKeys, seticeCreamOrdersKeys] = useState(null);
  const [iceCreamOrders, setIceCreamOrders] = useState(null);
  const [deletedOrder, setDeletedOrder] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [orderCopied, setOrderCopied] = useState(null);
  const [nameForOrder, setNameForOrder] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [messageWhenDisabled, setMessageWhenDisabled] = useState("");
  const [first3, setFirst3] = useState("");
  const [next3, setNext3] = useState("");
  const [last4, setLast4] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [additionaNotes, setAdditionalNotes] = useState("");

  const [showPlaceOrderModal, setShowPlaceOrderModal] = useState(false);

  const navigate = useNavigate();

  const openPlaceOrderModal = () => {
    document.body.classList.add("no-scroll");
    setShowPlaceOrderModal(true);
  };

  const closePlaceOrderModal = () => {
    document.body.classList.remove("no-scroll");
    setShowPlaceOrderModal(false);
  };

  const objectDeleted = (order) => {
    setDeletedOrder(order);
  };

  async function fetchOrders() {
    await fetch(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URL}/get-cart?` +
        new URLSearchParams({
          sessionID: sessionStorage.getItem("uuid"),
        }),
    )
      .then((response) => response.json())
      .then((customerOrder) => setCart(customerOrder));
  }

  async function submitCart() {
    let request_body = {};
    request_body["sessionID"] = sessionStorage.getItem("uuid");
    request_body["iceCreamOrders"] = iceCreamOrders;
    request_body["totalOrderCost"] = cart["totalOrderCost"];
    request_body["nameForOrder"] = nameForOrder;
    request_body["phoneNumber"] = phoneNumber;
    request_body["additionalNotes"] = additionaNotes;
    // eslint-disable-next-line no-undef
    await fetch(`${process.env.REACT_APP_API_URL}/place-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request_body),
    })
      .then((response) => response.json())
      .then((res) => console.log(res));
    closePlaceOrderModal();
    navigate("/");
  }

  useEffect(() => {
    if (cart === null || deletedOrder !== null) {
      fetchOrders();
    }

    setDeletedOrder(null);
  }, [cart, deletedOrder]);

  useEffect(() => {
    fetchOrders();
  }, [orderCopied]);

  useEffect(() => {
    if (cart !== null) {
      const orderKeys = Object.keys(cart);
      seticeCreamOrdersKeys(orderKeys.slice(0, -1));
      const orders = Object.values(cart);
      setIceCreamOrders(orders.slice(0, -1));
    } else {
      seticeCreamOrdersKeys([]);
      setIceCreamOrders([]);
    }
    setLoaded(true);
  }, [cart]);

  useEffect(() => {
    if (
      nameForOrder.match(/^[a-zA-Z][a-zA-Z-'\s]+$/) &&
      first3.match(/^[0-9]{3}$/) &&
      next3.match(/^[0-9]{3}$/) &&
      last4.match(/^[0-9]{4}$/)
    ) {
      setIsDisabled(false);
      setPhoneNumber(first3 + next3 + last4);
    } else {
      setIsDisabled(true);
      setMessageWhenDisabled(
        "Name should only contain letters, spaces, hypens, and apostrophes! Please make sure phone number is valid!",
      );
    }
  }, [nameForOrder, first3, next3, last4]);

  return (
    <div>
      <div className="cart-container">
        <h2 style={{ textAlign: "center" }}>View Cart</h2>
        {iceCreamOrders &&
          iceCreamOrders.map((order, key) => (
            // eslint-disable-next-line react/jsx-key
            <CartOrder
              order={order}
              orderKey={iceCreamOrdersKeys[key]}
              index={key}
              objectDeleted={objectDeleted}
              setOrderCopied={setOrderCopied}
            />
          ))}
        {iceCreamOrders?.length === 0 && loaded && (
          <div className="empty-cart-message">
            You currently have nothing in your cart! Click the button below for
            some delicious ice cream!
          </div>
        )}
        {cart && (
          <h3 style={{ textAlign: "center", margin: "10px" }}>
            Total Price: ${cart.totalOrderCost}
          </h3>
        )}
        <div className="place-order-container">
          <a href="order">Add New Order</a>
          {iceCreamOrders?.length !== 0 && loaded && (
            <button
              type="button"
              value="Place Order"
              onClick={() => openPlaceOrderModal()}
            >
              Place Order
            </button>
          )}
        </div>
      </div>
      <Modal
        show={showPlaceOrderModal}
        onClose={closePlaceOrderModal}
        onConfirm={submitCart}
        title={"Place Order?"}
        message={
          "Are you sure you would like to place this order? If so, please enter information below!"
        }
        isDisabled={isDisabled}
        messageWhenDisabled={messageWhenDisabled}
        leftButtonColor={"red"}
        rightButtonColor={"blue"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            marginBottom: "3%",
          }}
        >
          <label htmlFor="name" required>
            Name{" "}
          </label>
          <input
            style={{ width: "65%" }}
            type="text"
            id="name"
            placeholder="Enter Name Here..."
            onChange={(e) => {
              setNameForOrder(e.target.value);
            }}
            required
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "3%",
          }}
        >
          <label htmlFor="name">Phone Number </label>(
          <input
            style={{ width: "12%" }}
            type="text"
            id="name"
            onChange={(e) => {
              setFirst3(e.target.value);
            }}
          ></input>
          ) -
          <input
            style={{ width: "12%" }}
            type="text"
            id="name"
            onChange={(e) => {
              setNext3(e.target.value);
            }}
          ></input>
          -
          <input
            style={{ width: "12%" }}
            type="text"
            id="name"
            onChange={(e) => {
              setLast4(e.target.value);
            }}
          ></input>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <label htmlFor="name">Additional Notes</label>
          <textarea
            style={{ width: "65%" }}
            rows={3}
            onChange={(e) => {
              setAdditionalNotes(e.target.value);
            }}
          ></textarea>
        </div>
      </Modal>
    </div>
  );
}

export default Cart;
