import React from "react";
import "./SecondaryHeaderOwnerView.css";

function SecondaryHeaderOwnerView() {
  return (
    <div>
      <div className="secondary-header-component-owner-view">
        <h1>Creamy Scoops</h1>
      </div>
    </div>
  );
}

export default SecondaryHeaderOwnerView;
