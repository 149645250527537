import React from "react";
import PropTypes from "prop-types";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";
import "./FreeToppings.css";

const FreeToppingsForm = ({ freeToppings, setFreeToppings, menuItems }) => {
  const handleFreeToppingsForm = (newTopping) => {
    const indexTopping = freeToppings.find((topping) => newTopping === topping);
    let modifiedFreeToppings = [];
    if (indexTopping === undefined) {
      modifiedFreeToppings = [...freeToppings, newTopping];
    } else {
      modifiedFreeToppings = freeToppings.filter(
        (topping) => topping !== newTopping,
      );
    }
    setFreeToppings(modifiedFreeToppings);
  };

  const generateFreeToppingsForm = () => {
    return (
      <form className="free-toppings-form-options">
        {menuItems.map((freeTopping) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="free-toppings-option">
              <input
                type="checkbox"
                name="freeToppingsOptions"
                onChange={() => handleFreeToppingsForm(freeTopping)}
                checked={freeToppings.includes(freeTopping)}
              ></input>
              <div className="free-toppings-label">
                <div>{freeTopping}</div>
              </div>
            </div>
          );
        })}
      </form>
    );
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Free Toppings"}
        formFunction={generateFreeToppingsForm}
      />
    </div>
  );
};

FreeToppingsForm.propTypes = {
  freeToppings: PropTypes.array,
  setFreeToppings: PropTypes.func,
  menuItems: PropTypes.array,
};

export default FreeToppingsForm;
