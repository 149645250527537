import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faPenToSquare,
  faTrash,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import "./CollapsibleContainerCart.css";

function CollapsibleContainerCart({
  formTitle,
  displayContent,
  editOrder,
  copyOrder,
  deleteOrder,
}) {
  const [open, setOpen] = useState(true);

  const handleCollapse = () => {
    setOpen(!open);
  };

  return (
    <div className="collapsible-container-cart">
      <div className="collapsible-heading">
        <h3>{formTitle}</h3>
        <div>
          <button onClick={editOrder}>
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button onClick={copyOrder}>
            <FontAwesomeIcon icon={faCopy} />
          </button>
          <button onClick={deleteOrder}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button onClick={handleCollapse}>
            <FontAwesomeIcon
              className={`icon ${open ? "" : "closed"}`}
              icon={faChevronDown}
            />
          </button>
        </div>
      </div>
      <div className={`container-content ${open ? "" : "closed"}`}>
        {displayContent()}
      </div>
    </div>
  );
}

CollapsibleContainerCart.propTypes = {
  formTitle: PropTypes.string,
  displayContent: PropTypes.func,
  editOrder: PropTypes.func,
  copyOrder: PropTypes.func,
  deleteOrder: PropTypes.func,
};

export default CollapsibleContainerCart;
