import React from "react";
import PropTypes from "prop-types";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";
import "./FlavorsForm.css";

const FlavorsForm = ({ flavors, numberOfScoops, setFlavors, menuItems }) => {
  const handleFlavorsSelection = (newFlavor) => {
    let modifiedFlavors = [...flavors];
    if (flavors.includes(newFlavor)) {
      const indexToRemove = flavors.indexOf(newFlavor);
      modifiedFlavors.splice(indexToRemove, 1);
      modifiedFlavors.push(null);
    } else {
      const numberOfFlavorsChosen = flavors.filter(
        (flavor) => flavor !== null,
      ).length;
      if (numberOfScoops > numberOfFlavorsChosen) {
        let previouslyChosenFlavors = flavors.slice(0, -1);
        if (previouslyChosenFlavors.length === 0) {
          let nullFlavorsToAdd = numberOfScoops - numberOfFlavorsChosen;
          modifiedFlavors = [newFlavor];
          modifiedFlavors.push(...Array(nullFlavorsToAdd).fill(null));
        } else {
          modifiedFlavors = [newFlavor, ...previouslyChosenFlavors];
        }
      }
    }
    setFlavors(modifiedFlavors);
  };

  const generateIngredientsWarning = (df, gf, sf) => {
    const ingredientsWarningList = [];
    if (df !== "No") {
      ingredientsWarningList.push("DF");
    }
    if (gf !== "No") {
      ingredientsWarningList.push("GF");
    }
    if (sf !== "No") {
      ingredientsWarningList.push("SF");
    }

    return ingredientsWarningList.join(", ");
  };

  const generateFlavorsForm = () => {
    return (
      <form className="flavors-form-options">
        {menuItems.map((flavorData) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="flavors-form-option">
              <input
                type="checkbox"
                name={"iceCreamFlavorsOptions-" + flavorData.flavorName}
                onChange={() => handleFlavorsSelection(flavorData.flavorName)}
                checked={flavors.includes(flavorData.flavorName)}
                disabled={
                  flavors.filter((flavor) => flavor === null).length ===
                    3 - numberOfScoops &&
                  !flavors.includes(flavorData.flavorName)
                }
              ></input>
              <div className="flavors-label">
                <div>{flavorData.flavorName}</div>
              </div>
              <div className="ingredients-information">
                {generateIngredientsWarning(
                  flavorData.dairyFree,
                  flavorData.glutenFree,
                  flavorData.sugarFree,
                )}
              </div>
            </div>
          );
        })}
      </form>
    );
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Flavors"}
        formFunction={generateFlavorsForm}
      />
    </div>
  );
};

FlavorsForm.propTypes = {
  flavors: PropTypes.array,
  numberOfScoops: PropTypes.number,
  setFlavors: PropTypes.func,
  menuItems: PropTypes.array,
};

export default FlavorsForm;
