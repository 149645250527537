import React from "react";
import PropTypes from "prop-types";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";
import "./CupConeBowlForm.css";

const CupConeBowlForm = ({
  cupConeBowl,
  setCupConeBowl,
  menuItems,
  priceBreakdown,
  setPriceBreakdown,
}) => {
  const handleConeOrBowlForm = (cupConeBowl) => {
    setCupConeBowl(cupConeBowl.optionName);
    setPriceBreakdown({
      ...priceBreakdown,
      cupConeBowl: parseFloat(cupConeBowl.price),
    });
  };

  const generateConeOrBowlForm = () => {
    return (
      <form className="cup-cone-bowl-form-options">
        {menuItems.map((cupConeBowlOption) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="cup-cone-bowl-option">
              <input
                type="radio"
                name="cupConeBowlOptions"
                onChange={() => handleConeOrBowlForm(cupConeBowlOption)}
                checked={cupConeBowl === cupConeBowlOption.optionName}
              ></input>
              <div className="cup-cone-bowl-label">
                <div>{cupConeBowlOption.optionName}</div>
                <div style={{ fontSize: "small" }}>
                  {cupConeBowlOption.price === "0" && <div>No Charge</div>}
                  {cupConeBowlOption.price !== "0" && (
                    <div>${cupConeBowlOption.price}</div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </form>
    );
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Cup, Cone, or Bowl"}
        formFunction={generateConeOrBowlForm}
      />
    </div>
  );
};

CupConeBowlForm.propTypes = {
  cupConeBowl: PropTypes.string,
  setCupConeBowl: PropTypes.func,
  menuItems: PropTypes.array,
  priceBreakdown: PropTypes.object,
  setPriceBreakdown: PropTypes.func,
};

export default CupConeBowlForm;
