import React from "react";
import PropTypes from "prop-types";
import "./CoffeeMenu.css";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";

const CoffeeMenu = ({ menuItems }) => {
  const generateCoffeeMenu = () => {
    return (
      <div className="coffee-menu-options-container">
        {
          // eslint-disable-next-line no-unused-vars
          menuItems.coffee.options.map((coffee, key) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="coffee-menu-option">
                <div className="menu-option-label">
                  <div>{coffee.coffee}</div>
                  <div style={{ fontSize: "small" }}>{coffee.description}</div>
                  <div style={{ fontSize: "small" }}>${coffee.price}</div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  const generateFlavorsMenu = () => {
    return (
      <div className="coffee-menu-options-container">
        {menuItems.coffee.flavors.map((coffeeFlavor) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="flavors-menu-option">
              <div className="menu-option-label">
                <div>{coffeeFlavor.flavorName}</div>
              </div>
              <div className="flavors-menu-option-ingredients-info">
                {generateIngredientsWarning(coffeeFlavor.sugarFree)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateIngredientsWarning = (sf) => {
    const ingredientsWarningList = [];
    if (sf !== "No") {
      ingredientsWarningList.push("SF");
    }

    return ingredientsWarningList.join(", ");
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Coffee"}
        formFunction={generateCoffeeMenu}
      />
      <CollapsibleContainerOrder
        formTitle={"Flavors"}
        formFunction={generateFlavorsMenu}
      />
    </div>
  );
};

CoffeeMenu.propTypes = {
  menuItems: PropTypes.object,
};

export default CoffeeMenu;
