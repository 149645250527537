import React from "react";
import PropTypes from "prop-types";
import "./MenuSelection.css";

function MenuSelection({ title, description, menuItems, children }) {
  return (
    <div>
      <div className="menu-selection-container">
        <h2 className="title">{title}</h2>
        <div className="description">{description}</div>
      </div>
      {menuItems && (
        <div className="menu-selection-children-container">{children}</div>
      )}
    </div>
  );
}

MenuSelection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  menuItems: PropTypes.object,
  children: PropTypes.object,
};

export default MenuSelection;
