import React from "react";
import "./AboutUs.css";

function AboutUs() {
  return (
    <div>
      <div className="about-us-component">
        <h2>A Quick Message From Us</h2>
        <div>
          Welcome to Creamy Scoops, your ultimate destination for delightful
          treats in the South Charlotte area! At Creamy Scoops, we pride
          ourselves on providing an exceptional and welcoming experience for all
          our customers. We believe that every visit should be met with friendly
          service, a welcoming atmosphere, and, of course, delicious ice cream.
        </div>
        <br></br>
        <div>
          Whether you&apos;re a fan of classic favorites like chocolate and
          vanilla or crave something more adventurous like salted caramel or
          mint chocolate chip, we have something for everyone. Along with our
          wide range of delicious ice cream flavors, we also serve coffee,
          sundaes, milkshakes, and ice cream floats! We also offer a range of
          options, including gluten-free, vegan, sugar-free, and dairy-free
          alternatives. No matter your dietary restrictions, you can enjoy our
          creamy delights without compromise.
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
