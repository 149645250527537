import React from "react";
import "./OrderNow.css";

function OrderNow() {
  return (
    <div>
      <div className="order-now-component">
        <h2>Order Here!</h2>
        <div className="order-now-content">
          Indulge in your favorite frozen treats with just a few clicks! Here at
          Creamy Scoops, we&apos;ve made it effortless for you to order all your
          cravings. From creamy ice creams and pint-sized delights to refreshing
          coffees, decadent sundaes, and indulgent milkshakes, our menu has
          something for everyone. Whether you&apos;re in the mood for a classic
          scoop or a custom creation, simply browse our selection, place your
          order, and get ready to enjoy a sweet and satisfying experience
          delivered right to your door. So go ahead, treat yourself – you
          deserve it!
        </div>
      </div>
      <div className="grid-wrapper">
        <a href="ice-cream-order/new-order" className="grid-item">
          Ice Cream
        </a>
        <div className="grid-item">
          Ice Cream Pint
          <br></br>
          <div style={{ fontSize: "1rem", fontWeight: "100" }}>
            Coming Soon!
          </div>
        </div>
        <div className="grid-item">
          Coffee
          <br></br>
          <div style={{ fontSize: "1rem", fontWeight: "100" }}>
            Coming Soon!
          </div>
        </div>
        <div className="grid-item">
          Sundaes
          <br></br>
          <div style={{ fontSize: "1rem", fontWeight: "100" }}>
            Coming Soon!
          </div>
        </div>
        <div className="grid-item">
          Milkshakes
          <br></br>
          <div style={{ fontSize: "1rem", fontWeight: "100" }}>
            Coming Soon!
          </div>
        </div>
        <div className="grid-item">
          Drinks
          <br></br>
          <div style={{ fontSize: "1rem", fontWeight: "100" }}>
            Coming Soon!
          </div>
        </div>
        <div className="grid-item">
          Ice Cream Floats
          <br></br>
          <div style={{ fontSize: "1rem", fontWeight: "100" }}>
            Coming Soon!
          </div>
        </div>
        <div className="grid-item">
          Other
          <br></br>
          <div style={{ fontSize: "1rem", fontWeight: "100" }}>
            Coming Soon!
          </div>
        </div>
      </div>
      <div className="promotional-deals-component">
        <h2>Promotional Deals</h2>
        <div className="promotional-deals-content">
          <div className="promotional-deal-item">
            Mother&apos;s Day Special
            <br></br>
            <div style={{ fontSize: "1rem", fontWeight: "100" }}>
              Buy your mom a rose!
            </div>
            <div style={{ fontSize: "0.75rem", fontWeight: "100" }}>
              Promo expires 5/13/2024
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderNow;
