import React from "react";
import PropTypes from "prop-types";
import "./FrappeMenu.css";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";

const FrappeMenu = ({ menuItems }) => {
  const generateFrappeMenu = () => {
    return (
      <div className="frappe-menu-options-container">
        {
          // eslint-disable-next-line no-unused-vars
          menuItems.frappe.map((frappe, key) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="frappe-menu-option">
                <div className="menu-option-label">
                  <div>{frappe.frappe}</div>
                  <div style={{ fontSize: "small" }}>{frappe.description}</div>
                  <div style={{ fontSize: "small" }}>${frappe.price}</div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Frappe"}
        formFunction={generateFrappeMenu}
      />
    </div>
  );
};

FrappeMenu.propTypes = {
  menuItems: PropTypes.object,
};

export default FrappeMenu;
