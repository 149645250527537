import React from "react";
import PropTypes from "prop-types";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";
import "./AddOns.css";

const AddOnsForm = ({
  addOns,
  setAddOns,
  menuItems,
  priceBreakdown,
  setPriceBreakdown,
}) => {
  const handleAddOnsForm = (newAddOn) => {
    const indexAddOn = addOns.find((addOn) => newAddOn === addOn);
    let modifiedAddOns = [];
    if (indexAddOn === undefined) {
      modifiedAddOns = [...addOns, newAddOn];
    } else {
      modifiedAddOns = addOns.filter((addOn) => addOn !== newAddOn);
    }

    setPriceBreakdown({
      ...priceBreakdown,
      addOns: parseFloat(modifiedAddOns.length),
    });
    setAddOns(modifiedAddOns);
  };

  const generateAddOnsForm = () => {
    return (
      <div style={{ width: "100%" }}>
        <h6
          style={{
            marginTop: "2%",
            marginBottom: "1%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Each add-on is $1.00 extra
        </h6>
        <form className="add-ons-form-options">
          {menuItems.map((addOn) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="add-on-option">
                <input
                  type="checkbox"
                  name="addOnsOptions"
                  onChange={() => handleAddOnsForm(addOn)}
                  checked={addOns.includes(addOn)}
                ></input>
                <div className="add-on-label">
                  <div>{addOn}</div>
                </div>
              </div>
            );
          })}
        </form>
      </div>
    );
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Add Ons"}
        formFunction={generateAddOnsForm}
      />
    </div>
  );
};

AddOnsForm.propTypes = {
  addOns: PropTypes.array,
  setAddOns: PropTypes.func,
  menuItems: PropTypes.array,
  priceBreakdown: PropTypes.object,
  setPriceBreakdown: PropTypes.func,
};

export default AddOnsForm;
