import React from "react";
import PropTypes from "prop-types";
import "./Modal.css";

function Modal({
  show,
  onClose,
  onConfirm,
  title,
  message,
  isDisabled,
  messageWhenDisabled,
  leftButtonColor,
  rightButtonColor,
  children,
}) {
  if (show !== true) {
    return null;
  }

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h3 className="modal-title">{title}</h3>
        </div>
        <div className="modal-message">{message}</div>
        {children !== undefined && <div className="modal-body">{children}</div>}
        <div className="modal-footer">
          {isDisabled && (
            <div style={{ marginBottom: "3%" }}>{messageWhenDisabled}</div>
          )}
          <div className="modal-buttons">
            <button
              style={{ backgroundColor: leftButtonColor }}
              onClick={onClose}
            >
              Close
            </button>
            <button
              type="submit"
              style={{ backgroundColor: rightButtonColor }}
              onClick={onConfirm}
              disabled={isDisabled}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  isDisabled: PropTypes.bool,
  messageWhenDisabled: PropTypes.string,
  leftButtonColor: PropTypes.string,
  rightButtonColor: PropTypes.string,
  children: PropTypes.object,
};

export default Modal;
