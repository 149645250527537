import React from "react";
import PropTypes from "prop-types";
import "./BobaMenu.css";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";

const BobaMenu = ({ menuItems }) => {
  const generateBobaSize = () => {
    return (
      <div className="boba-menu-options-container">
        {
          // eslint-disable-next-line no-unused-vars
          menuItems.boba.options.map((boba, key) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="boba-menu-option">
                <div className="menu-option-label">
                  <div>{boba.boba}</div>
                  <div style={{ fontSize: "small" }}>${boba.price}</div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  const generateMilkTeaFlavors = () => {
    return (
      <div className="boba-menu-options-container">
        {menuItems.boba.flavors.milkTea.map((milkTeaFlavor) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="boba-menu-option">
              <div className="menu-option-label">
                <div>{milkTeaFlavor}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateFruitTeaFlavors = () => {
    return (
      <div className="boba-menu-options-container">
        {menuItems.boba.flavors.fruitTea.map((fruitTeaFlavor) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="boba-menu-option">
              <div className="menu-option-label">
                <div>{fruitTeaFlavor}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateDrinkToppingsRegular = () => {
    return (
      <div className="boba-menu-options-container">
        <h5
          style={{
            marginTop: "0%",
            marginBottom: "1%",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          Each additional topping is $0.50 extra
        </h5>
        {menuItems.boba.toppings.regular.map((regularTopping) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="boba-menu-option">
              <div className="menu-option-label">
                <div>{regularTopping.regularTopping}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateDrinkToppingsCrystal = () => {
    return (
      <div className="boba-menu-options-container">
        <h5
          style={{
            marginTop: "0%",
            marginBottom: "1%",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          Each additional topping is $0.90 extra
        </h5>
        {menuItems.boba.toppings.crystal.map((crystalTopping) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="boba-menu-option">
              <div className="menu-option-label">
                <div>{crystalTopping.crystalTopping}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const generateDrinkToppingsJelly = () => {
    return (
      <div className="boba-menu-options-container">
        <h5
          style={{
            marginTop: "0%",
            marginBottom: "1%",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          Each additional topping is $0.90 extra
        </h5>
        {menuItems.boba.toppings.jelly.map((jellyTopping) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div className="boba-menu-option">
              <div className="menu-option-label">
                <div>{jellyTopping.jellyTopping}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Boba Size"}
        formFunction={generateBobaSize}
      />
      <CollapsibleContainerOrder
        formTitle={"Milk Tea Flavors"}
        formFunction={generateMilkTeaFlavors}
      />
      <CollapsibleContainerOrder
        formTitle={"Fruit Tea Flavors"}
        formFunction={generateFruitTeaFlavors}
      />
      <CollapsibleContainerOrder
        formTitle={"Drink Toppings (Regular)"}
        formFunction={generateDrinkToppingsRegular}
      />
      <CollapsibleContainerOrder
        formTitle={"Drink Toppings (Crystal)"}
        formFunction={generateDrinkToppingsCrystal}
      />
      <CollapsibleContainerOrder
        formTitle={"Drink Toppings (Jelly)"}
        formFunction={generateDrinkToppingsJelly}
      />
    </div>
  );
};

BobaMenu.propTypes = {
  menuItems: PropTypes.object,
};

export default BobaMenu;
