import React from "react";
import "./Home.css";
import ImageGallery from "./ImageGallery";
import AboutUs from "./AboutUs";

function Home() {
  return (
    <div>
      <AboutUs />
      <ImageGallery />
    </div>
  );
}

export default Home;
