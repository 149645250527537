import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import "./CollapsibleContainerOrder.css";

function CollapsibleContainerOrder({ formTitle, formFunction }) {
  const [open, setOpen] = useState(true);

  const handleCollapse = () => {
    setOpen(!open);
  };

  return (
    <div>
      <div className="collapsible-container-order">
        <div className="collapsible-heading">
          <h3>
            {formTitle}
            {!sessionStorage.getItem("featureFlag") &&
              (formTitle === "Number of Scoops" ||
                formTitle === "Cup, Cone, or Bowl" ||
                formTitle === "Flavors") && (
                <sup style={{ color: "red" }}>*</sup>
              )}
          </h3>
          {/* {!sessionStorage.getItem('featureFlag') &&  */}
          <button onClick={handleCollapse}>
            <FontAwesomeIcon
              className={`icon ${open ? "" : "closed"}`}
              icon={faChevronDown}
            />
          </button>
          {/* } */}
        </div>
        <div className={`container-content ${open ? "" : "closed"}`}>
          {formFunction()}
        </div>
      </div>
    </div>
  );
}

CollapsibleContainerOrder.propTypes = {
  formTitle: PropTypes.string,
  formFunction: PropTypes.func,
};

export default CollapsibleContainerOrder;
