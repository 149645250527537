import React from "react";
import NavBarOwnerView from "../Shared/NavBarOwnerView";
import SecondaryHeaderOwnerView from "./SecondaryHeaderOwnerView";
import "./OwnerView.css";

function OwnerView() {
  // const [placedOrdersKeys, setPlacedOrdersKeys] = useState(null);
  // const [placedOrders, setPlacedOrders] = useState(null);

  // useEffect(() => {
  //   async function getPlacedOrders() {
  //     // eslint-disable-next-line no-undef
  //     await fetch(`${process.env.REACT_APP_API_URL}/get-placed-orders`)
  //       .then((response) => response.json())
  //       .then((placedOrders) => {
  //         if (placedOrders !== null) {
  //           setPlacedOrdersKeys(Object.keys(placedOrders));
  //           setPlacedOrders(placedOrders);
  //         }
  //       });
  //   }

  //   getPlacedOrders();
  // }, []);

  // return (
  //   <div>
  //     <NavBarOwnerView />
  //     <SecondaryHeaderOwnerView />
  //     {placedOrders && (
  //       <div className="placed-orders-container">
  //         {placedOrdersKeys.map((placedOrderKey) => {
  //           return (
  //             // eslint-disable-next-line react/jsx-key
  //             <div>
  //               <PlacedOrderContainer
  //                 placedOrderInfo={placedOrders[placedOrderKey]}
  //                 placedOrderKey={placedOrderKey}
  //               />
  //             </div>
  //           );
  //         })}
  //       </div>
  //     )}
  //   </div>
  // );

  async function setMenu() {
    // eslint-disable-next-line no-undef
    await fetch(`${process.env.REACT_APP_API_URL}/set-menu`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
      },
    });
    alert("New menu was set!");
  }

  async function setPromotionalContent() {
    // eslint-disable-next-line no-undef
    await fetch(`${process.env.REACT_APP_API_URL}/set-promotional-deals`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
      },
    });
    alert("New promotional content was set!");
  }

  async function setFlavorOfTheWeek() {
    // eslint-disable-next-line no-undef
    await fetch(`${process.env.REACT_APP_API_URL}/set-flavor-of-the-week`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-Type": "application/json",
      },
    });
    alert("New flavor of the week was set!");
  }

  return (
    <div>
      <NavBarOwnerView />
      <SecondaryHeaderOwnerView />
      <div className="condenseScreen">
        <h2>Google Sheet</h2>
        <div className="admin-container">
          <a
            href="https://docs.google.com/spreadsheets/d/1SHubBSZ3wShz7NUJHA2NtzXfmmmKA6QJJgVnUaT_fAc/edit?usp=sharing"
            className="admin-button"
          >
            Edit Google Sheet!
          </a>
        </div>
        <h2>Actions</h2>
        <div className="admin-container">
          <button className="admin-button" onClick={() => setMenu()}>
            Set Menu!
          </button>
          <br></br>
          <button
            className="admin-button"
            onClick={() => setPromotionalContent()}
          >
            Set Promotional Content!
          </button>
          <br></br>
          <button className="admin-button" onClick={() => setFlavorOfTheWeek()}>
            Set Flavor of the Week!
          </button>
        </div>
      </div>
    </div>
  );
}

export default OwnerView;
