import React from "react";
import "./NavBar.css";

function NavBar() {
  return (
    <div>
      <div className="navbar-component">
        <div className="navbar-logo">
          <a href="/">
            <img
              src="/images/Creamy_Scoops_Logo.jpeg"
              width="55px"
              style={{ marginRight: "5px" }}
            ></img>
            <div className="navbar-log-text">Creamy Scoops</div>
          </a>
        </div>
        {sessionStorage.getItem("featureFlag") ? (
          <ul className="navbar-tabs">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/menu">Menu</a>
            </li>
          </ul>
        ) : (
          <ul className="navbar-tabs">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/order">Order</a>
            </li>
            <li>
              <a href="/cart">Cart</a>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}

export default NavBar;
