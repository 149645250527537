import React from "react";
import PropTypes from "prop-types";
import "./SmoothiesMenu.css";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";

const SmoothiesMenu = ({ menuItems }) => {
  const generateSmoothiesMenu = () => {
    return (
      <div className="smoothies-menu-options-container">
        {
          // eslint-disable-next-line no-unused-vars
          menuItems.smoothies.map((smoothie, key) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="smoothies-menu-option">
                <div className="menu-option-label">
                  <div style={{ marginBottom: "5px", fontSize: "large" }}>
                    {smoothie.smoothie}
                  </div>
                  <div style={{ fontSize: "small" }}>
                    {smoothie.description}
                  </div>
                  <div style={{ fontSize: "small" }}>${smoothie.price}</div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Smoothies"}
        formFunction={generateSmoothiesMenu}
      />
    </div>
  );
};

SmoothiesMenu.propTypes = {
  menuItems: PropTypes.object,
};

export default SmoothiesMenu;
