import React from "react";
import PropTypes from "prop-types";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";
import "./NumberOfScoopsForm.css";

const NumberOfScoopsFrom = ({
  numberOfScoops,
  setNumberOfScoops,
  setOldNumberOfScoops,
  menuItems,
  priceBreakdown,
  setPriceBreakdown,
}) => {
  const handleNumberOfScoopsSelection = (label, price) => {
    if (label === "1 Scoop") {
      setNumberOfScoops(1);
    } else if (label === "2 Scoops") {
      setNumberOfScoops(2);
    } else if (label === "3 Scoops") {
      setNumberOfScoops(3);
    }
    setPriceBreakdown({
      ...priceBreakdown,
      numberOfScoops: parseFloat(price),
    });
    setOldNumberOfScoops(numberOfScoops);
  };

  const generateNumberOfScoopsForm = () => {
    return (
      <form className="number-of-scoops-form-options">
        {
          // eslint-disable-next-line no-unused-vars
          menuItems.map((numberOfScoopsData, key) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="number-of-scoops-option">
                <input
                  type="radio"
                  name="numberOfScoopsOptions"
                  onChange={() =>
                    handleNumberOfScoopsSelection(
                      numberOfScoopsData.optionName,
                      numberOfScoopsData.price,
                    )
                  }
                  checked={
                    parseFloat(numberOfScoopsData.numberOfScoops) ===
                    numberOfScoops
                  }
                ></input>
                <div className="number-of-scoops-label">
                  <div>{numberOfScoopsData.optionName}</div>
                  <div style={{ fontSize: "small" }}>
                    ${numberOfScoopsData.price}
                  </div>
                </div>
              </div>
            );
          })
        }
      </form>
    );
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Number of Scoops"}
        formFunction={generateNumberOfScoopsForm}
      />
    </div>
  );
};

NumberOfScoopsFrom.propTypes = {
  numberOfScoops: PropTypes.number,
  setNumberOfScoops: PropTypes.func,
  setOldNumberOfScoops: PropTypes.func,
  menuItems: PropTypes.array,
  priceBreakdown: PropTypes.object,
  setPriceBreakdown: PropTypes.func,
};

export default NumberOfScoopsFrom;
