import React from "react";
import { useState } from "react";
import NavBarOwnerView from "../Shared/NavBarOwnerView";
import SecondaryHeaderOwnerView from "./SecondaryHeaderOwnerView";
import "./ProtectedRoutes.css";
import OwnerView from "./OwnerView";

function ProtectedRoutes() {
  const [password, setPassword] = useState("");
  const [response, setResponse] = useState(false);

  const hashValue = (val) =>
    crypto.subtle
      .digest("SHA-256", new TextEncoder("utf-8").encode(val))
      .then((h) => {
        let hexes = [],
          view = new DataView(h);
        for (let i = 0; i < view.byteLength; i += 4)
          hexes.push(("00000000" + view.getUint32(i).toString(16)).slice(-8));
        return hexes.join("");
      });

  async function login() {
    hashValue(password).then(async (password) => {
      await fetch(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_API_URL}/login?` +
          new URLSearchParams({
            encryptedPassword: password,
          }),
      )
        .then((response) => response.json())
        .then((response) => {
          if (response) {
            setResponse(true);
            sessionStorage.setItem("loggedIn", true);
          } else {
            document.getElementById("password").value = "";
            setPassword("");
            alert("Incorrect! Please try again!");
          }
        });
    });
  }

  return response || sessionStorage.getItem("loggedIn") ? (
    <OwnerView />
  ) : (
    <div>
      <NavBarOwnerView />
      <SecondaryHeaderOwnerView />
      <div className="login-container">
        <div className="password-container">
          <label htmlFor="name" required>
            Password
          </label>
          <input
            style={{ width: "10%" }}
            type="password"
            id="password"
            placeholder="Enter Password Here..."
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button onClick={() => login()}>Login</button>
      </div>
    </div>
  );
}

export default ProtectedRoutes;
