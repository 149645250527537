import React from "react";
import PropTypes from "prop-types";
import CollapsibleContainerOrder from "../../Shared/CollapsibleContainerOrder";
import "./Toppings.css";

const ToppingsForm = ({
  toppings,
  setToppings,
  menuItems,
  priceBreakdown,
  setPriceBreakdown,
}) => {
  const handleToppingsForm = (newTopping) => {
    const indexTopping = toppings.find((topping) => newTopping === topping);
    let modifiedToppings = [];
    if (indexTopping === undefined) {
      modifiedToppings = [...toppings, newTopping];
    } else {
      modifiedToppings = toppings.filter((topping) => topping !== newTopping);
    }
    setPriceBreakdown({
      ...priceBreakdown,
      toppings: parseFloat(modifiedToppings.length * 0.5),
    });
    setToppings(modifiedToppings);
  };

  const generateToppingsForm = () => {
    return (
      <div style={{ width: "100%" }}>
        <h6
          style={{
            marginTop: "2%",
            marginBottom: "1%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Each additional topping is $0.50 extra
        </h6>
        <form className="toppings-form-options">
          {menuItems.map((topping) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <div className="toppings-option">
                <input
                  type="checkbox"
                  name="toppingsOptions"
                  onChange={() => handleToppingsForm(topping)}
                  checked={toppings.includes(topping)}
                ></input>
                <div className="toppings-label">
                  <div>{topping}</div>
                </div>
              </div>
            );
          })}
        </form>
      </div>
    );
  };

  return (
    <div>
      <CollapsibleContainerOrder
        formTitle={"Toppings"}
        formFunction={generateToppingsForm}
      />
    </div>
  );
};

ToppingsForm.propTypes = {
  toppings: PropTypes.array,
  setToppings: PropTypes.func,
  menuItems: PropTypes.array,
  priceBreakdown: PropTypes.object,
  setPriceBreakdown: PropTypes.func,
};

export default ToppingsForm;
