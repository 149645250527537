import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CollapsibleContainerCart from "../Shared/CollapsibleContainerCart";
import Modal from "../Shared/Modal";
import "./Cart.css";

function CartOrder({ order, orderKey, index, objectDeleted, setOrderCopied }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const openDeleteModal = () => {
    document.body.classList.add("no-scroll");
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    document.body.classList.remove("no-scroll");
    setShowDeleteModal(false);
  };

  const navigate = useNavigate();

  async function deleteOrder() {
    let request = {};
    request["sessionID"] = sessionStorage.getItem("uuid");
    request["orderKey"] = orderKey;
    // eslint-disable-next-line no-undef
    await fetch(`${process.env.REACT_APP_API_URL}/delete-order-item`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((deletedOrder) => objectDeleted(deletedOrder));
    closeDeleteModal();
  }

  async function editOrder() {
    navigate("/ice-cream-order/edit-order/" + orderKey, {
      state: { order: order, orderKey: orderKey },
    });
  }

  async function copyOrder() {
    let request = {};
    request["sessionID"] = sessionStorage.getItem("uuid");
    request["orderID"] = orderKey;
    // eslint-disable-next-line no-undef
    await fetch(`${process.env.REACT_APP_API_URL}/copy-order-item`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    })
      .then((response) => response.json())
      .then((res) => setOrderCopied(res));
  }

  function displayOrderTable() {
    return (
      <div className="order-table">
        {showDeleteModal && document.body.classList.add("no-scroll")}
        <table>
          <thead>
            <th align="left">Selection</th>
            <th align="left" className="total-column">
              Price
            </th>
          </thead>
          <tbody>
            <tr>
              <td>
                {order?.orderDetails.numberOfScoops}{" "}
                {(order?.orderDetails.numberOfScoops === 1 && "scoop") ||
                  (order?.orderDetails.numberOfScoops > 1 && "scoops")}{" "}
                ({order?.orderDetails.flavors.join(", ")})
              </td>
              <td className="total-column">
                $
                {(
                  Math.round(order?.priceBreakdown.numberOfScoops * 100) / 100
                ).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>{order?.orderDetails.coneOrBowl}</td>
              <td className="total-column">
                $
                {(
                  Math.round(order?.priceBreakdown.cupConeBowl * 100) / 100
                ).toFixed(2)}
              </td>
            </tr>
            {(order?.orderDetails.toppings ||
              order?.orderDetails.freeToppings) && (
              <tr>
                <td>
                  {order?.orderDetails.freeToppings &&
                    order?.orderDetails.freeToppings
                      .map((freeTopping) => freeTopping + " (free)")
                      .join(", ")}
                  {order?.orderDetails.toppings &&
                    order?.orderDetails.freeToppings &&
                    ", "}
                  {order?.orderDetails.toppings &&
                    order?.orderDetails.toppings.join(", ")}
                </td>
                <td className="total-column">
                  $
                  {(
                    Math.round(order?.priceBreakdown.toppings * 100) / 100
                  ).toFixed(2)}
                </td>
              </tr>
            )}
            {order?.orderDetails.addOns && (
              <tr>
                <td>{order?.orderDetails.addOns.join(", ")}</td>
                <td className="total-column">
                  $
                  {(
                    Math.round(order?.priceBreakdown.addOns * 100) / 100
                  ).toFixed(2)}
                </td>
              </tr>
            )}
            <tr style={{ borderTop: "0.5px solid", fontWeight: "bold" }}>
              <td>Total Item Price</td>
              <td className="total-column">
                ${(Math.round(order?.totalItemCost * 100) / 100).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <CollapsibleContainerCart
        formTitle={`Item ${index + 1} (${order.orderType})`}
        displayContent={displayOrderTable}
        editOrder={editOrder}
        copyOrder={copyOrder}
        deleteOrder={openDeleteModal}
      />
      <Modal
        show={showDeleteModal}
        onClose={closeDeleteModal}
        onConfirm={deleteOrder}
        title={"Delete Order?"}
        message={"Are you sure you would like to delete this order?"}
        isDisabled={false}
        messageWhenDisabled={""}
        leftButtonColor={"blue"}
        rightButtonColor={"red"}
      />
    </div>
  );
}

CartOrder.propTypes = {
  order: PropTypes.object,
  orderKey: PropTypes.string,
  index: PropTypes.number,
  objectDeleted: PropTypes.object,
  setOrderCopied: PropTypes.func,
};

export default CartOrder;
