import React from "react";
import "./ContactUs.css";

function ContactUs() {
  return (
    <div>
      <div className="contact-us-component">
        <h2>Contact Information</h2>
        <div className="contact-us-content">
          <div className="contact-us-child-component">
            <div className="contact-us-child-component-content sub-heading">
              Store Information
            </div>
            <div className="contact-us-child-component-content">
              10005 Charlotte Hwy #101 Fort Mill, SC 29707
            </div>
            <div className="contact-us-child-component-content">
              (803)-431-7148
            </div>
          </div>
          <div className="contact-us-child-component">
            <div className="contact-us-child-component-content sub-heading">
              Store Hours
            </div>
            <div className="contact-us-child-component-content">
              Sunday: 12 PM - 9 PM
            </div>
            <div className="contact-us-child-component-content">
              Monday - Thursday: 1 PM - 9 PM
            </div>
            <div className="contact-us-child-component-content">
              Friday - Saturday: 12 PM - 10 PM
            </div>
          </div>
          <div className="social-media">
            <a
              href="https://www.instagram.com/creamy.scoops.clt/?img_index=3"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/instagram.svg"
                alt="Instagram Logo"
                srcSet="/images/instagram.svg"
              />
            </a>
            <a
              href="https://www.facebook.com/share/aGzeWiWowMJfCSAx/?mibextid=WC7FNe"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/facebook.svg"
                alt="Facebook Logo"
                srcSet="/images/facebook.svg"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
